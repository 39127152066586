<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <div class="verify-div">
        <div class="img-dev">
          <img class="img1" :src="portal29">
          <p class="verify-t">{{ $t('ebClaim.SubmitSuccessTitle') }}</p>
          <p class="verify-s">{{ $t('ebClaim.SubmitSuccessDesc') }}</p>
        </div>

        <div class="btn-group">
          <Button
            class="fu-v2-btn danger block"
            round
            block
            type="danger"
            @click="$router.push({name: 'EbTrackClaim'})"
          >{{ $t('ebClaim.TrackingClaim') }}</Button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Button } from 'vant'
import portal29 from '@/assets/imgs/portal29.png'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { Button },
  props: {},
  data() {
    return {
      portal29
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
}
.verify-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
}
.img-dev {
  margin-top: 111px;
  text-align: center;
}
.img1 {
  width: 247px;
}
.verify-t {
  font-size: 16px;
  font-family: 'Montserrat-Bold';
  margin: 15px 0;
}
.verify-s {
  font-size: 12px;
  margin: 0 45px;
  text-align: center;
}
.btn-group {
  margin: 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
